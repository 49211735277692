import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

// suppress compile error: Observable<object> has not property, data
interface GraphqlResult {
  data?: any;
  errors?: [object];
  extensions?: {
    [key: string]: any;
  };
}
class GraphqlClient {

  async createSystemMaintenance(input) {
    return API.graphql(graphqlOperation(mutations.createSystemMaintenance, { input })) as Promise<GraphqlResult>;
  }

  async updateSystemMaintenance(input) {
    return API.graphql(graphqlOperation(mutations.updateSystemMaintenance, { input })) as Promise<GraphqlResult>;
  }

  async getSystemMaintenance(id) {
    return API.graphql(graphqlOperation(queries.getSystemMaintenance, { id: id })) as Promise<GraphqlResult>;
  }

  async createMarketStatus(input) {
    return API.graphql(graphqlOperation(mutations.createMarketStatus, { input })) as Promise<GraphqlResult>;
  }

  async updateMarketStatus(input) {
    return API.graphql(graphqlOperation(mutations.updateMarketStatus, { input })) as Promise<GraphqlResult>;
  }

  async getMarketStatus(id) {
    return API.graphql(graphqlOperation(queries.getMarketStatus, { id: id })) as Promise<GraphqlResult>;
  }

  async createUserAccess(input) {
    return API.graphql(graphqlOperation(mutations.createUserAccess, { input })) as Promise<GraphqlResult>;
  }

  async updateUserAccess(input) {
    return API.graphql(graphqlOperation(mutations.updateUserAccess, { input })) as Promise<GraphqlResult>;
  }

  async getUserAccess(id) {
    return API.graphql(graphqlOperation(queries.getUserAccess, { id: id })) as Promise<GraphqlResult>;
  }

  async listUserAccesss(filter) {
    const userAccesss = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listUserAccesss, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listUserAccesss.items;
      userAccesss.push(...items);
      nextToken = result.data.listUserAccesss.nextToken;
    } while (nextToken);
    return userAccesss;
  }

  async createUserAccessHistory(input) {
    return API.graphql(graphqlOperation(mutations.createUserAccessHistory, { input })) as Promise<GraphqlResult>;
  }

  async updateUserAccessHistory(input) {
    return API.graphql(graphqlOperation(mutations.updateUserAccessHistory, { input })) as Promise<GraphqlResult>;
  }

  async getUserAccessHistory(id) {
    return API.graphql(graphqlOperation(queries.getUserAccessHistory, { id: id })) as Promise<GraphqlResult>;
  }

  async listUserAccessHistorys(filter) {
    const userAccessHistorys = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listUserAccessHistorys, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listUserAccessHistorys.items;
      userAccessHistorys.push(...items);
      nextToken = result.data.listUserAccessHistorys.nextToken;
    } while (nextToken);
    return userAccessHistorys;
  }

  async getUser(id) {
    return API.graphql(graphqlOperation(queries.getUser, { id: id })) as Promise<GraphqlResult>;
  }

  async registerUser(input) {
    return API.graphql(graphqlOperation(mutations.registerUser, { input })) as Promise<GraphqlResult>;
  }

  async updateUser(input) {
    return API.graphql(graphqlOperation(mutations.updateUser, { input })) as Promise<GraphqlResult>;
  }

  async listUsers(filter) {
    const users = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listUsers, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listUsers.items;
      users.push(...items);
      nextToken = result.data.listUsers.nextToken;
    } while (nextToken);
    return users;
  }

  async createRemisierProfile(input) {
    return API.graphql(graphqlOperation(mutations.createRemisierProfile, { input })) as Promise<GraphqlResult>;
  }

  async updateRemisierProfile(input) {
    return API.graphql(graphqlOperation(mutations.updateRemisierProfile, { input })) as Promise<GraphqlResult>;
  }

  async deleteRemisierProfile(input) {
    return API.graphql(graphqlOperation(mutations.deleteRemisierProfile, { input })) as Promise<GraphqlResult>;
  }

  async getRemisierProfile(id) {
    return API.graphql(graphqlOperation(queries.getRemisierProfile, { id: id })) as Promise<GraphqlResult>;
  }

  async listRemisierProfiles(filter) {
    const profiles = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listRemisierProfiles, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listRemisierProfiles.items;
      profiles.push(...items);
      nextToken = result.data.listRemisierProfiles.nextToken;
    } while (nextToken);
    return profiles;
  }

  async createInvitation(input) {
    return API.graphql(graphqlOperation(mutations.createInvitation, { input })) as Promise<GraphqlResult>;
  }

  async updateInvitation(input) {
    return API.graphql(graphqlOperation(mutations.updateInvitation, { input })) as Promise<GraphqlResult>;
  }

  async deleteInvitation(input) {
    return API.graphql(graphqlOperation(mutations.deleteInvitation, { input })) as Promise<GraphqlResult>;
  }

  async getInvitation(id) {
    return API.graphql(graphqlOperation(queries.getInvitation, { id: id })) as Promise<GraphqlResult>;
  }

  async listInvitations(filter) {
    const invitations = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listInvitations, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listInvitations.items;
      invitations.push(...items);
      nextToken = result.data.listInvitations.nextToken;
    } while (nextToken);
    return invitations;
  }

  async createCdsClientActivationEmail(input) {
    return API.graphql(graphqlOperation(mutations.createCdsClientActivationEmail, { input })) as Promise<GraphqlResult>;
  }

  async updateCdsClientActivationEmail(input) {
    return API.graphql(graphqlOperation(mutations.updateCdsClientActivationEmail, { input })) as Promise<GraphqlResult>;
  }

  async deleteCdsClientActivationEmail(input) {
    return API.graphql(graphqlOperation(mutations.deleteCdsClientActivationEmail, { input })) as Promise<GraphqlResult>;
  }

  async getCdsClientActivationEmail(id) {
    return API.graphql(graphqlOperation(queries.getCdsClientActivationEmail, { id: id })) as Promise<GraphqlResult>;
  }

  async listCdsClientActivationEmails(filter) {
    const cdsClientActivationEmails = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listCdsClientActivationEmails, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listCdsClientActivationEmails.items;
      cdsClientActivationEmails.push(...items);
      nextToken = result.data.listCdsClientActivationEmails.nextToken;
    } while (nextToken);
    return cdsClientActivationEmails;
  }

  async createRemisierCdsClientActivationEmailRequest(input) {
    return API.graphql(graphqlOperation(mutations.createRemisierCdsClientActivationEmailRequest, { input })) as Promise<GraphqlResult>;
  }

  async updateRemisierCdsClientActivationEmailRequest(input) {
    return API.graphql(graphqlOperation(mutations.updateRemisierCdsClientActivationEmailRequest, { input })) as Promise<GraphqlResult>;
  }

  async deleteRemisierCdsClientActivationEmailRequest(input) {
    return API.graphql(graphqlOperation(mutations.deleteRemisierCdsClientActivationEmailRequest, { input })) as Promise<GraphqlResult>;
  }

  async getRemisierCdsClientActivationEmailRequest(id) {
    return API.graphql(graphqlOperation(queries.getRemisierCdsClientActivationEmailRequest, { id: id })) as Promise<GraphqlResult>;
  }

  async listRemisierCdsClientActivationEmailRequests(filter) {
    const remisierCdsClientActivationEmailRequests = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listRemisierCdsClientActivationEmailRequests, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listRemisierCdsClientActivationEmailRequests.items;
      remisierCdsClientActivationEmailRequests.push(...items);
      nextToken = result.data.listRemisierCdsClientActivationEmailRequests.nextToken;
    } while (nextToken);
    return remisierCdsClientActivationEmailRequests;
  }

  async createAccountActivation(input) {
    return API.graphql(graphqlOperation(mutations.createAccountActivation, { input })) as Promise<GraphqlResult>;
  }

  async updateAccountActivation(input) {
    return API.graphql(graphqlOperation(mutations.updateAccountActivation, { input })) as Promise<GraphqlResult>;
  }

  async deleteAccountActivation(input) {
    return API.graphql(graphqlOperation(mutations.deleteAccountActivation, { input })) as Promise<GraphqlResult>;
  }

  async getAccountActivation(id) {
    return API.graphql(graphqlOperation(queries.getAccountActivation, { id: id })) as Promise<GraphqlResult>;
  }

  async listAccountActivations(filter) {
    const accountActivations = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listAccountActivations, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listAccountActivations.items;
      accountActivations.push(...items);
      nextToken = result.data.listAccountActivations.nextToken;
    } while (nextToken);
    return accountActivations;
  }

  async createReferralActivation(input) {
    return API.graphql(graphqlOperation(mutations.createReferralActivation, { input })) as Promise<GraphqlResult>;
  }

  async updateReferralActivation(input) {
    return API.graphql(graphqlOperation(mutations.updateReferralActivation, { input })) as Promise<GraphqlResult>;
  }

  async deleteReferralActivation(input) {
    return API.graphql(graphqlOperation(mutations.deleteReferralActivation, { input })) as Promise<GraphqlResult>;
  }

  async getReferralActivation(id) {
    return API.graphql(graphqlOperation(queries.getReferralActivation, { id: id })) as Promise<GraphqlResult>;
  }

  async listReferralActivations(filter) {
    const referralActivations = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listReferralActivations, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listReferralActivations.items;
      referralActivations.push(...items);
      nextToken = result.data.listReferralActivations.nextToken;
    } while (nextToken);
    return referralActivations;
  }

  async createTradingOrder(input) {
    return API.graphql(graphqlOperation(mutations.createTradingOrder, { input })) as Promise<GraphqlResult>;
  }

  async updateTradingOrder(input) {
    return API.graphql(graphqlOperation(mutations.updateTradingOrder, { input })) as Promise<GraphqlResult>;
  }

  async deleteTradingOrder(input) {
    return API.graphql(graphqlOperation(mutations.deleteTradingOrder, { input })) as Promise<GraphqlResult>;
  }

  async getTradingOrder(id) {
    return API.graphql(graphqlOperation(queries.getTradingOrder, { id: id })) as Promise<GraphqlResult>;
  }

  async listTradingOrders(filter) {
    const tradingOrders = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listTradingOrders, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listTradingOrders.items;
      tradingOrders.push(...items);
      nextToken = result.data.listTradingOrders.nextToken;
    } while (nextToken);
    return tradingOrders;
  }

  async createClientReferral(input) {
    return API.graphql(graphqlOperation(mutations.createClientReferral, { input })) as Promise<GraphqlResult>;
  }

  async updateClientReferral(input) {
    return API.graphql(graphqlOperation(mutations.updateClientReferral, { input })) as Promise<GraphqlResult>;
  }

  async deleteClientReferral(input) {
    return API.graphql(graphqlOperation(mutations.deleteClientReferral, { input })) as Promise<GraphqlResult>;
  }

  async getClientReferral(id) {
    return API.graphql(graphqlOperation(queries.getClientReferral, { id: id })) as Promise<GraphqlResult>;
  }

  async listClientReferrals(filter) {
    const clientReferrals = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listClientReferrals, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listClientReferrals.items;
      clientReferrals.push(...items);
      nextToken = result.data.listClientReferrals.nextToken;
    } while (nextToken);
    return clientReferrals;
  }

  async createRemisierClientLimit(input) {
    return API.graphql(graphqlOperation(mutations.createRemisierClientLimit, { input })) as Promise<GraphqlResult>;
  }

  async createCdsClient(input) {
    return API.graphql(graphqlOperation(mutations.createCdsClient, { input })) as Promise<GraphqlResult>;
  }

  async updateCdsClient(input) {
    return API.graphql(graphqlOperation(mutations.updateCdsClient, { input })) as Promise<GraphqlResult>;
  }

  async deleteCdsClient(input) {
    return API.graphql(graphqlOperation(mutations.deleteCdsClient, { input })) as Promise<GraphqlResult>;
  }

  async getCdsClient(id) {
    return API.graphql(graphqlOperation(queries.getCdsClient, { id: id })) as Promise<GraphqlResult>;
  }

  async listCdsClients(filter) {
    const cdsClients = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listCdsClients, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listCdsClients.items;
      cdsClients.push(...items);
      nextToken = result.data.listCdsClients.nextToken;
    } while (nextToken);
    return cdsClients;
  }

  async createQuestionnaire(input) {
    return API.graphql(graphqlOperation(mutations.createQuestionnaire, { input })) as Promise<GraphqlResult>;
  }

  async updateQuestionnaire(input) {
    return API.graphql(graphqlOperation(mutations.updateQuestionnaire, { input })) as Promise<GraphqlResult>;
  }

  async deleteQuestionnaire(input) {
    return API.graphql(graphqlOperation(mutations.deleteQuestionnaire, { input })) as Promise<GraphqlResult>;
  }

  async getQuestionnaire(id) {
    return API.graphql(graphqlOperation(queries.getQuestionnaire, { id: id })) as Promise<GraphqlResult>;
  }

  async listQuestionnaires(filter) {
    const questionnaires = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listQuestionnaires, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listQuestionnaires.items;
      questionnaires.push(...items);
      nextToken = result.data.listQuestionnaires.nextToken;
    } while (nextToken);
    return questionnaires;
  }

  async createFeedback(input) {
    return API.graphql(graphqlOperation(mutations.createFeedback, { input })) as Promise<GraphqlResult>;
  }

  async updateFeedback(input) {
    return API.graphql(graphqlOperation(mutations.updateFeedback, { input })) as Promise<GraphqlResult>;
  }

  async deleteFeedback(input) {
    return API.graphql(graphqlOperation(mutations.deleteFeedback, { input })) as Promise<GraphqlResult>;
  }

  async getFeedback(id) {
    return API.graphql(graphqlOperation(queries.getFeedback, { id: id })) as Promise<GraphqlResult>;
  }

  async listFeedbacks(filter) {
    const feedbacks = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listFeedbacks, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listFeedbacks.items;
      feedbacks.push(...items);
      nextToken = result.data.listFeedbacks.nextToken;
    } while (nextToken);
    return feedbacks;
  }

  async createClientSurvey(input) {
    return API.graphql(graphqlOperation(mutations.createClientSurvey, { input })) as Promise<GraphqlResult>;
  }

  async updateClientSurvey(input) {
    return API.graphql(graphqlOperation(mutations.updateClientSurvey, { input })) as Promise<GraphqlResult>;
  }

  async deleteClientSurvey(input) {
    return API.graphql(graphqlOperation(mutations.deleteClientSurvey, { input })) as Promise<GraphqlResult>;
  }

  async getClientSurvey(id) {
    return API.graphql(graphqlOperation(queries.getClientSurvey, { id: id })) as Promise<GraphqlResult>;
  }

  async listClientSurveys(filter) {
    const clientSurveys = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listClientSurveys, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listClientSurveys.items;
      clientSurveys.push(...items);
      nextToken = result.data.listClientSurveys.nextToken;
    } while (nextToken);
    return clientSurveys;
  }

  async createPlatformSubscription(input) {
    return API.graphql(graphqlOperation(mutations.createPlatformSubscription, { input })) as Promise<GraphqlResult>;
  }

  async updatePlatformSubscription(input) {
    return API.graphql(graphqlOperation(mutations.updatePlatformSubscription, { input })) as Promise<GraphqlResult>;
  }

  async getPlatformSubscription(id) {
    return API.graphql(graphqlOperation(queries.getPlatformSubscription, { id: id })) as Promise<GraphqlResult>;
  }

  async listPlatformSubscriptions(filter) {
    const platformSubscriptions = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listPlatformSubscriptions, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listPlatformSubscriptions.items;
      platformSubscriptions.push(...items);
      nextToken = result.data.listPlatformSubscriptions.nextToken;
    } while (nextToken);
    return platformSubscriptions;
  }

  async createPremiumSubscription(input) {
    return API.graphql(graphqlOperation(mutations.createPremiumSubscription, { input })) as Promise<GraphqlResult>;
  }

  async updatePremiumSubscription(input) {
    return API.graphql(graphqlOperation(mutations.updatePremiumSubscription, { input })) as Promise<GraphqlResult>;
  }

  async getPremiumSubscription(id) {
    return API.graphql(graphqlOperation(queries.getPremiumSubscription, { id: id })) as Promise<GraphqlResult>;
  }

  async listPremiumSubscriptions(filter) {
    const premiumSubscriptions = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listPremiumSubscriptions, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listPremiumSubscriptions.items;
      premiumSubscriptions.push(...items);
      nextToken = result.data.listPremiumSubscriptions.nextToken;
    } while (nextToken);
    return premiumSubscriptions;
  }

  async createRemisierSubscription(input) {
    return API.graphql(graphqlOperation(mutations.createRemisierSubscription, { input })) as Promise<GraphqlResult>;
  }

  async updateRemisierSubscription(input) {
    return API.graphql(graphqlOperation(mutations.updateRemisierSubscription, { input })) as Promise<GraphqlResult>;
  }

  async getRemisierSubscription(id) {
    return API.graphql(graphqlOperation(queries.getRemisierSubscription, { id: id })) as Promise<GraphqlResult>;
  }

  async listRemisierSubscriptions(filter) {
    const remisierSubscriptions = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listRemisierSubscriptions, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listRemisierSubscriptions.items;
      remisierSubscriptions.push(...items);
      nextToken = result.data.listRemisierSubscriptions.nextToken;
    } while (nextToken);
    return remisierSubscriptions;
  }

  async createNewRemisierSubscription(input) {
    return API.graphql(graphqlOperation(mutations.createNewRemisierSubscription, { input })) as Promise<GraphqlResult>;
  }

  async updateNewRemisierSubscription(input) {
    return API.graphql(graphqlOperation(mutations.updateNewRemisierSubscription, { input })) as Promise<GraphqlResult>;
  }

  async getNewRemisierSubscription(id) {
    return API.graphql(graphqlOperation(queries.getNewRemisierSubscription, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteNewRemisierSubscription(input) {
    return API.graphql(graphqlOperation(mutations.deleteNewRemisierSubscription, { input })) as Promise<GraphqlResult>;
  }

  async listNewRemisierSubscriptions(filter) {
    const newRemisierSubscriptions = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listNewRemisierSubscriptions, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listNewRemisierSubscriptions.items;
      newRemisierSubscriptions.push(...items);
      nextToken = result.data.listNewRemisierSubscriptions.nextToken;
    } while (nextToken);
    return newRemisierSubscriptions;
  }

  async createNewRemisierSubscriptionEmail(input) {
    return API.graphql(graphqlOperation(mutations.createNewRemisierSubscriptionEmail, { input })) as Promise<GraphqlResult>;
  }

  async updateNewRemisierSubscriptionEmail(input) {
    return API.graphql(graphqlOperation(mutations.updateNewRemisierSubscriptionEmail, { input })) as Promise<GraphqlResult>;
  }

  async getNewRemisierSubscriptionEmail(id) {
    return API.graphql(graphqlOperation(queries.getNewRemisierSubscriptionEmail, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteNewRemisierSubscriptionEmail(input) {
    return API.graphql(graphqlOperation(mutations.deleteNewRemisierSubscriptionEmail, { input })) as Promise<GraphqlResult>;
  }

  async listNewRemisierSubscriptionEmails(filter) {
    const newRemisierSubscriptionEmails = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listNewRemisierSubscriptionEmails, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listNewRemisierSubscriptionEmails.items;
      newRemisierSubscriptionEmails.push(...items);
      nextToken = result.data.listNewRemisierSubscriptionEmails.nextToken;
    } while (nextToken);
    return newRemisierSubscriptionEmails;
  }

  async createBroker(input) {
    return API.graphql(graphqlOperation(mutations.createBroker, { input })) as Promise<GraphqlResult>;
  }

  async updateBroker(input) {
    return API.graphql(graphqlOperation(mutations.updateBroker, { input })) as Promise<GraphqlResult>;
  }

  async getBroker(id) {
    return API.graphql(graphqlOperation(queries.getBroker, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteBroker(input) {
    return API.graphql(graphqlOperation(mutations.deleteBroker, { input })) as Promise<GraphqlResult>;
  }

  async listBrokers(filter) {
    const brokers = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listBrokers, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listBrokers.items;
      brokers.push(...items);
      nextToken = result.data.listBrokers.nextToken;
    } while (nextToken);
    return brokers;
  }

  async createLatestUpdate(input) {
    return API.graphql(graphqlOperation(mutations.createLatestUpdate, { input })) as Promise<GraphqlResult>;
  }

  async updateLatestUpdate(input) {
    return API.graphql(graphqlOperation(mutations.updateLatestUpdate, { input })) as Promise<GraphqlResult>;
  }

  async getLatestUpdate(id) {
    return API.graphql(graphqlOperation(queries.getLatestUpdate, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteLatestUpdate(input) {
    return API.graphql(graphqlOperation(mutations.deleteLatestUpdate, { input })) as Promise<GraphqlResult>;
  }

  async listLatestUpdates(filter) {
    const latestUpdates = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listLatestUpdates, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listLatestUpdates.items;
      latestUpdates.push(...items);
      nextToken = result.data.listLatestUpdates.nextToken;
    } while (nextToken);
    return latestUpdates;
  }

  async createTradingJournal(input) {
    return API.graphql(graphqlOperation(mutations.createTradingJournal, { input })) as Promise<GraphqlResult>;
  }

  async updateTradingJournal(input) {
    return API.graphql(graphqlOperation(mutations.updateTradingJournal, { input })) as Promise<GraphqlResult>;
  }

  async getTradingJournal(id) {
    return API.graphql(graphqlOperation(queries.getTradingJournal, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteTradingJournal(input) {
    return API.graphql(graphqlOperation(mutations.deleteTradingJournal, { input })) as Promise<GraphqlResult>;
  }

  async listTradingJournals(filter) {
    const tradingJournals = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listTradingJournals, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listTradingJournals.items;
      tradingJournals.push(...items);
      nextToken = result.data.listTradingJournals.nextToken;
    } while (nextToken);
    return tradingJournals;
  }

  async createJournal(input) {
    return API.graphql(graphqlOperation(mutations.createJournal, { input })) as Promise<GraphqlResult>;
  }

  async updateJournal(input) {
    return API.graphql(graphqlOperation(mutations.updateJournal, { input })) as Promise<GraphqlResult>;
  }

  async getJournal(id) {
    return API.graphql(graphqlOperation(queries.getJournal, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteJournal(input) {
    return API.graphql(graphqlOperation(mutations.deleteJournal, { input })) as Promise<GraphqlResult>;
  }

  async listJournals(filter) {
    const journals = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listJournals, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listJournals.items;
      journals.push(...items);
      nextToken = result.data.listJournals.nextToken;
    } while (nextToken);
    return journals;
  }

  async createStockMapping(input) {
    return API.graphql(graphqlOperation(mutations.createStockMapping, { input })) as Promise<GraphqlResult>;
  }

  async updateStockMapping(input) {
    return API.graphql(graphqlOperation(mutations.updateStockMapping, { input })) as Promise<GraphqlResult>;
  }

  async getStockMapping(id) {
    return API.graphql(graphqlOperation(queries.getStockMapping, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteStockMapping(input) {
    return API.graphql(graphqlOperation(mutations.deleteStockMapping, { input })) as Promise<GraphqlResult>;
  }

  async listStockMappings(filter) {
    const stockMappings = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listStockMappings, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listStockMappings.items;
      stockMappings.push(...items);
      nextToken = result.data.listStockMappings.nextToken;
    } while (nextToken);
    return stockMappings;
  }

  async createBuyCall(input) {
    return API.graphql(graphqlOperation(mutations.createBuyCall, { input })) as Promise<GraphqlResult>;
  }

  async updateBuyCall(input) {
    return API.graphql(graphqlOperation(mutations.updateBuyCall, { input })) as Promise<GraphqlResult>;
  }

  async getBuyCall(id) {
    return API.graphql(graphqlOperation(queries.getBuyCall, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteBuyCall(input) {
    return API.graphql(graphqlOperation(mutations.deleteBuyCall, { input })) as Promise<GraphqlResult>;
  }

  async listBuyCalls(filter) {
    const buyCalls = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listBuyCalls, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listBuyCalls.items;
      buyCalls.push(...items);
      nextToken = result.data.listBuyCalls.nextToken;
    } while (nextToken);
    return buyCalls;
  }

  async createOpenJournal(input) {
    return API.graphql(graphqlOperation(mutations.createOpenJournal, { input })) as Promise<GraphqlResult>;
  }

  async updateOpenJournal(input) {
    return API.graphql(graphqlOperation(mutations.updateOpenJournal, { input })) as Promise<GraphqlResult>;
  }

  async getOpenJournal(id) {
    return API.graphql(graphqlOperation(queries.getOpenJournal, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteOpenJournal(input) {
    return API.graphql(graphqlOperation(mutations.deleteOpenJournal, { input })) as Promise<GraphqlResult>;
  }

  async listOpenJournals(filter) {
    const openJournals = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listOpenJournals, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listOpenJournals.items;
      openJournals.push(...items);
      nextToken = result.data.listOpenJournals.nextToken;
    } while (nextToken);
    return openJournals;
  }

  async createPortfolio(input) {
    return API.graphql(graphqlOperation(mutations.createPortfolio, { input })) as Promise<GraphqlResult>;
  }

  async updatePortfolio(input) {
    return API.graphql(graphqlOperation(mutations.updatePortfolio, { input })) as Promise<GraphqlResult>;
  }

  async getPortfolio(id) {
    return API.graphql(graphqlOperation(queries.getPortfolio, { id: id })) as Promise<GraphqlResult>;
  }

  async deletePortfolio(input) {
    return API.graphql(graphqlOperation(mutations.deletePortfolio, { input })) as Promise<GraphqlResult>;
  }

  async listPortfolios(filter) {
    const portfolios = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listPortfolios, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listPortfolios.items;
      portfolios.push(...items);
      nextToken = result.data.listPortfolios.nextToken;
    } while (nextToken);
    return portfolios;
  }

  async createVerification(input) {
    return API.graphql(graphqlOperation(mutations.createVerification, { input })) as Promise<GraphqlResult>;
  }

  async updateVerification(input) {
    return API.graphql(graphqlOperation(mutations.updateVerification, { input })) as Promise<GraphqlResult>;
  }

  async getVerification(id) {
    return API.graphql(graphqlOperation(queries.getVerification, { id: id })) as Promise<GraphqlResult>;
  }

  async listVerifications(filter) {
    const verifications = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listVerifications, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listVerifications.items;
      verifications.push(...items);
      nextToken = result.data.listVerifications.nextToken;
    } while (nextToken);
    return verifications;
  }

  async createStockNews(input) {
    return API.graphql(graphqlOperation(mutations.createStockNews, { input })) as Promise<GraphqlResult>;
  }

  async updateStockNews(input) {
    return API.graphql(graphqlOperation(mutations.updateStockNews, { input })) as Promise<GraphqlResult>;
  }

  async deleteStockNews(input) {
    return API.graphql(graphqlOperation(mutations.deleteStockNews, { input })) as Promise<GraphqlResult>;
  }

  async getStockNews(id) {
    return API.graphql(graphqlOperation(queries.getStockNews, { id: id })) as Promise<GraphqlResult>;
  }

  async listStockNewss(filter) {
    const stockNewss = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listStockNewss, { filter, limit: 1000, nextToken })) as GraphqlResult;
      const items = result.data.listStockNewss.items;
      stockNewss.push(...items);
      nextToken = result.data.listStockNewss.nextToken;
    } while (nextToken);
    return stockNewss;
  }

  async createStockDetails(input) {
    return API.graphql(graphqlOperation(mutations.createStockDetails, { input })) as Promise<GraphqlResult>;
  }

  async updateStockDetails(input) {
    return API.graphql(graphqlOperation(mutations.updateStockDetails, { input })) as Promise<GraphqlResult>;
  }

  async getStockDetails(id) {
    return API.graphql(graphqlOperation(queries.getStockDetails, { id: id })) as Promise<GraphqlResult>;
  }

  async listStockDetailss(filter) {
    const stockDetailss = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listStockDetailss, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listStockDetailss.items;
      stockDetailss.push(...items);
      nextToken = result.data.listStockDetailss.nextToken;
    } while (nextToken);
    return stockDetailss;
  }

  async createOtherStockScreener(input) {
    return API.graphql(graphqlOperation(mutations.createOtherStockScreener, { input })) as Promise<GraphqlResult>;
  }

  async updateOtherStockScreener(input) {
    return API.graphql(graphqlOperation(mutations.updateOtherStockScreener, { input })) as Promise<GraphqlResult>;
  }

  async getOtherStockScreener(id) {
    return API.graphql(graphqlOperation(queries.getOtherStockScreener, { id: id })) as Promise<GraphqlResult>;
  }

  async listOtherStockScreeners(filter) {
    const otherStockScreeners = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listOtherStockScreeners, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listOtherStockScreeners.items;
      otherStockScreeners.push(...items);
      nextToken = result.data.listOtherStockScreeners.nextToken;
    } while (nextToken);
    return otherStockScreeners;
  }

  async createWatchList(input) {
    return API.graphql(graphqlOperation(mutations.createWatchList, { input })) as Promise<GraphqlResult>;
  }

  async updateWatchList(input) {
    return API.graphql(graphqlOperation(mutations.updateWatchList, { input })) as Promise<GraphqlResult>;
  }

  async deleteWatchList(input) {
    return API.graphql(graphqlOperation(mutations.deleteWatchList, { input })) as Promise<GraphqlResult>;
  }

  async getWatchList(id) {
    return API.graphql(graphqlOperation(queries.getWatchList, { id: id })) as Promise<GraphqlResult>;
  }

  async listWatchLists(filter) {
    const watchLists = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listWatchLists, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listWatchLists.items;
      watchLists.push(...items);
      nextToken = result.data.listWatchLists.nextToken;
    } while (nextToken);
    return watchLists;
  }

  async createStockDetailsHistory(input) {
    return API.graphql(graphqlOperation(mutations.createStockDetailsHistory, { input })) as Promise<GraphqlResult>;
  }

  async updateStockDetailsHistory(input) {
    return API.graphql(graphqlOperation(mutations.updateStockDetailsHistory, { input })) as Promise<GraphqlResult>;
  }

  async getStockDetailsHistory(id) {
    return API.graphql(graphqlOperation(queries.getStockDetailsHistory, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteDetailsHistory(input) {
    return API.graphql(graphqlOperation(mutations.deleteStockDetailsHistory, { input })) as Promise<GraphqlResult>;
  }

  async listStockDetailsHistorys(filter) {
    const stockDetailsHistorys = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listStockDetailsHistorys, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listStockDetailsHistorys.items;
      stockDetailsHistorys.push(...items);
      nextToken = result.data.listStockDetailsHistorys.nextToken;
    } while (nextToken);
    return stockDetailsHistorys;
  }

  async createPaperTrade(input) {
    return API.graphql(graphqlOperation(mutations.createPaperTrade, { input })) as Promise<GraphqlResult>;
  }

  async updatePaperTrade(input) {
    return API.graphql(graphqlOperation(mutations.updatePaperTrade, { input })) as Promise<GraphqlResult>;
  }

  async getPaperTrade(id) {
    return API.graphql(graphqlOperation(queries.getPaperTrade, { id: id })) as Promise<GraphqlResult>;
  }

  async deletePaperTrade(input) {
    return API.graphql(graphqlOperation(mutations.deletePaperTrade, { input })) as Promise<GraphqlResult>;
  }

  async listPaperTrades(filter) {
    const paperTrades = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listPaperTrades, { filter, limit: 10000, nextToken })) as GraphqlResult;
      const items = result.data.listPaperTrades.items;
      paperTrades.push(...items);
      nextToken = result.data.listPaperTrades.nextToken;
    } while (nextToken);
    return paperTrades;
  }

  async createTradingSchool(input) {
    return API.graphql(graphqlOperation(mutations.createTradingSchool, { input })) as Promise<GraphqlResult>;
  }

  async updateTradingSchool(input) {
    return API.graphql(graphqlOperation(mutations.updateTradingSchool, { input })) as Promise<GraphqlResult>;
  }

  async getTradingSchool(id) {
    return API.graphql(graphqlOperation(queries.getTradingSchool, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteTradingSchool(input) {
    return API.graphql(graphqlOperation(mutations.deleteTradingSchool, { input })) as Promise<GraphqlResult>;
  }

  async listTradingSchools(filter) {
    const tradingSchools = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listTradingSchools, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listTradingSchools.items;
      tradingSchools.push(...items);
      nextToken = result.data.listTradingSchools.nextToken;
    } while (nextToken);
    return tradingSchools;
  }

  async createPremiumDailyEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.createPremiumDailyEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async updatePremiumDailyEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.updatePremiumDailyEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async getPremiumDailyEmailNotification(id) {
    return API.graphql(graphqlOperation(queries.getPremiumDailyEmailNotification, { id: id })) as Promise<GraphqlResult>;
  }

  async deletePremiumDailyEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.deletePremiumDailyEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async listPremiumDailyEmailNotifications(filter) {
    const premiumDailyEmailNotifications = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listPremiumDailyEmailNotifications, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listPremiumDailyEmailNotifications.items;
      premiumDailyEmailNotifications.push(...items);
      nextToken = result.data.listPremiumDailyEmailNotifications.nextToken;
    } while (nextToken);
    return premiumDailyEmailNotifications;
  }

  async createDailyEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.createDailyEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async updateDailyEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.updateDailyEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async getDailyEmailNotification(id) {
    return API.graphql(graphqlOperation(queries.getDailyEmailNotification, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteDailyEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.deleteDailyEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async listDailyEmailNotifications(filter) {
    const dailyEmailNotifications = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listDailyEmailNotifications, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listDailyEmailNotifications.items;
      dailyEmailNotifications.push(...items);
      nextToken = result.data.listDailyEmailNotifications.nextToken;
    } while (nextToken);
    return dailyEmailNotifications;
  }

  async createBtstEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.createBtstEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async updateBtstEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.updateBtstEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async getBtstEmailNotification(id) {
    return API.graphql(graphqlOperation(queries.getBtstEmailNotification, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteBtstEmailNotification(input) {
    return API.graphql(graphqlOperation(mutations.deleteBtstEmailNotification, { input })) as Promise<GraphqlResult>;
  }

  async listBtstEmailNotifications(filter) {
    const btstEmailNotifications = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listBtstEmailNotifications, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listBtstEmailNotifications.items;
      btstEmailNotifications.push(...items);
      nextToken = result.data.listBtstEmailNotifications.nextToken;
    } while (nextToken);
    return btstEmailNotifications;
  }

  async createLearningMaterial(input) {
    return API.graphql(graphqlOperation(mutations.createLearningMaterial, { input })) as Promise<GraphqlResult>;
  }

  async updateLearningMaterial(input) {
    return API.graphql(graphqlOperation(mutations.updateLearningMaterial, { input })) as Promise<GraphqlResult>;
  }

  async getLearningMaterial(id) {
    return API.graphql(graphqlOperation(queries.getLearningMaterial, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteLearningMaterial(input) {
    return API.graphql(graphqlOperation(mutations.deleteLearningMaterial, { input })) as Promise<GraphqlResult>;
  }

  async listLearningMaterials(filter) {
    const learningMaterials = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listLearningMaterials, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listLearningMaterials.items;
      learningMaterials.push(...items);
      nextToken = result.data.listLearningMaterials.nextToken;
    } while (nextToken);
    return learningMaterials;
  }

  async createForum(input) {
    return API.graphql(graphqlOperation(mutations.createForum, { input })) as Promise<GraphqlResult>;
  }

  async updateForum(input) {
    return API.graphql(graphqlOperation(mutations.updateForum, { input })) as Promise<GraphqlResult>;
  }

  async getForum(id) {
    return API.graphql(graphqlOperation(queries.getForum, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteForum(input) {
    return API.graphql(graphqlOperation(mutations.deleteForum, { input })) as Promise<GraphqlResult>;
  }

  async listForums(filter) {
    const forums = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listForums, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listForums.items;
      forums.push(...items);
      nextToken = result.data.listForums.nextToken;
    } while (nextToken);
    return forums;
  }

  async createForumThread(input) {
    return API.graphql(graphqlOperation(mutations.createForumThread, { input })) as Promise<GraphqlResult>;
  }

  async updateForumThread(input) {
    return API.graphql(graphqlOperation(mutations.updateForumThread, { input })) as Promise<GraphqlResult>;
  }

  async getForumThread(id) {
    return API.graphql(graphqlOperation(queries.getForumThread, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteForumThread(input) {
    return API.graphql(graphqlOperation(mutations.deleteForumThread, { input })) as Promise<GraphqlResult>;
  }

  async listForumThreads(filter) {
    const forumThreads = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listForumThreads, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listForumThreads.items;
      forumThreads.push(...items);
      nextToken = result.data.listForumThreads.nextToken;
    } while (nextToken);
    return forumThreads;
  }

  async createNewsletterEmail(input) {
    return API.graphql(graphqlOperation(mutations.createNewsletterEmail, { input })) as Promise<GraphqlResult>;
  }

  async updateNewsletterEmail(input) {
    return API.graphql(graphqlOperation(mutations.updateNewsletterEmail, { input })) as Promise<GraphqlResult>;
  }

  async getNewsletterEmail(id) {
    return API.graphql(graphqlOperation(queries.getNewsletterEmail, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteNewsletterEmail(input) {
    return API.graphql(graphqlOperation(mutations.deleteNewsletterEmail, { input })) as Promise<GraphqlResult>;
  }

  async listNewsletterEmails(filter) {
    const newsletterEmails = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listNewsletterEmails, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listNewsletterEmails.items;
      newsletterEmails.push(...items);
      nextToken = result.data.listNewsletterEmails.nextToken;
    } while (nextToken);
    return newsletterEmails;
  }

  async createRequestUpdate(input) {
    return API.graphql(graphqlOperation(mutations.createRequestUpdate, { input })) as Promise<GraphqlResult>;
  }

  async updateRequestUpdate(input) {
    return API.graphql(graphqlOperation(mutations.updateRequestUpdate, { input })) as Promise<GraphqlResult>;
  }

  async getRequestUpdate(id) {
    return API.graphql(graphqlOperation(queries.getRequestUpdate, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteRequestUpdate(input) {
    return API.graphql(graphqlOperation(mutations.deleteRequestUpdate, { input })) as Promise<GraphqlResult>;
  }

  async listRequestUpdates(filter) {
    const requestUpdates = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listRequestUpdates, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listRequestUpdates.items;
      requestUpdates.push(...items);
      nextToken = result.data.listRequestUpdates.nextToken;
    } while (nextToken);
    return requestUpdates;
  }

  async createRequestForm(input) {
    return API.graphql(graphqlOperation(mutations.createRequestForm, { input })) as Promise<GraphqlResult>;
  }

  async updateRequestForm(input) {
    return API.graphql(graphqlOperation(mutations.updateRequestForm, { input })) as Promise<GraphqlResult>;
  }

  async getRequestForm(id) {
    return API.graphql(graphqlOperation(queries.getRequestForm, { id: id })) as Promise<GraphqlResult>;
  }

  async deleteRequestForm(input) {
    return API.graphql(graphqlOperation(mutations.deleteRequestForm, { input })) as Promise<GraphqlResult>;
  }

  async listRequestForms(filter) {
    const requestForms = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listRequestForms, { filter, limit: 3000, nextToken })) as GraphqlResult;
      const items = result.data.listRequestForms.items;
      requestForms.push(...items);
      nextToken = result.data.listRequestForms.nextToken;
    } while (nextToken);
    return requestForms;
  }

  async createTechnicalView(input) {
    return API.graphql(graphqlOperation(mutations.createTechnicalView, { input })) as Promise<GraphqlResult>;
  }

  async updateTechnicalView(input) {
    return API.graphql(graphqlOperation(mutations.updateTechnicalView, { input })) as Promise<GraphqlResult>;
  }

  async deleteTechnicalView(input) {
    return API.graphql(graphqlOperation(mutations.deleteTechnicalView, { input })) as Promise<GraphqlResult>;
  }

  async getTechnicalView(id) {
    return API.graphql(graphqlOperation(queries.getTechnicalView, { id: id })) as Promise<GraphqlResult>;
  }

  async listTechnicalViews(filter) {
    const technicalViews = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listTechnicalViews, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listTechnicalViews.items;
      technicalViews.push(...items);
      nextToken = result.data.listTechnicalViews.nextToken;
    } while (nextToken);
    return technicalViews;
  }
}

export default new GraphqlClient();