import React, { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

const Homepage = lazy(() => import('../components/landing/Homepage'));
const ResetPassword = lazy(() => import('../components/account/ResetPassword'));
const Login = lazy(() => import('../components/account/Login'));
const VerifyAccount = lazy(() => import('../components/account/VerifyAccount'));
const SignUp = lazy(() => import('../components/account/SignUp'));
const EmailVerification = lazy(() => import('../components/account/EmailVerification'));
const SignUpSubscription = lazy(() => import('../components/account/SignUpSubscription'));
const SignUpMutualFunds = lazy(() => import('../components/account/SignUpMutualFunds'));
const RemisierPage = lazy(() => import('../components/landing/RemisierPage'));
const SignUpRemisier = lazy(() => import('../components/account/SignUpRemisier'));
const SignUpSubAdmin = lazy(() => import('../components/account/SignUpSubAdmin'));
const LoginElite = lazy(() => import('../components/account/LoginElite'));
const VerifyAccountElite = lazy(() => import('../components/account/VerifyAccountElite'));
const SignUpEliteReferral = lazy(() => import('../components/account/SignUpEliteReferral'));
const EmailVerificationElite = lazy(() => import('../components/account/EmailVerificationElite'));
const SignUpEliteInvitation = lazy(() => import('../components/account/SignUpEliteInvitation'));
const SignUpRemisierUniquePage = lazy(() => import('../components/account/SignUpRemisierUniquePage'));

const NoAuthRoutes = () => {
  return (
    <>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/login/ringgit-lab-elite" component={LoginElite} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/verify-account" component={VerifyAccount} />
      <Route exact path="/verify-account/ringgit-lab-elite" component={VerifyAccountElite} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/signup/remisier/:id" component={SignUpRemisierUniquePage} />
      <Route exact path="/signup/ringgit-lab-elite/invitation/:id" component={SignUpEliteInvitation} />
      <Route exact path="/signup/subscription" component={SignUpSubscription} />
      <Route exact path="/signup/private-mutual-funds" component={SignUpMutualFunds} />
      <Route exact path="/signup/remisier" component={SignUpRemisier} />
      <Route exact path="/signup/admin" component={SignUpSubAdmin} />
      <Route exact path="/email-verification" component={EmailVerification} />
      <Route exact path="/email-verification/ringgit-lab-elite" component={EmailVerificationElite} />
      <Route exact path="/referral/:id" component={SignUpEliteReferral} />
      {/* <Route exact path="/questionnaire" component={NoAuthCreateQuestionnaireResponse} /> */}
      <Route exact path="/remisier" component={RemisierPage} />
      {/* <Route exact path="/thank-you-for-your-response" component={QuestionnaireThankYouPage} />
      <Route exact path="/sorry-to-see-you-go" component={QuestionnaireSorryPage} /> */}

      <Route exact path="/terms/private-mutual-funds" render={() => <Redirect to="/login" />} />
      <Route exact path="/dashboard" render={() => <Redirect to="/login" />} />
      <Route exact path="/all-updates" render={() => <Redirect to="/login" />} />
      <Route exact path="/all-updates/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/settings" render={() => <Redirect to="/login" />} />
      <Route exact path="/journal" render={() => <Redirect to="/login" />} />
      <Route exact path="/journals" render={() => <Redirect to="/login" />} />
      <Route exact path="/all-journals" render={() => <Redirect to="/login" />} />
      <Route exact path="/journals/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/journal-confirmation" render={() => <Redirect to="/login" />} />
      <Route exact path="/admin-screener" render={() => <Redirect to="/login" />} />
      <Route exact path="/potential-stocks" render={() => <Redirect to="/login" />} />
      <Route exact path="/potential-stocks/premium" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener" render={() => <Redirect to="/login" />} />
      <Route exact path="/watchlist" render={() => <Redirect to="/login" />} />
      <Route exact path="/paper-trade" render={() => <Redirect to="/login" />} />
      <Route exact path="/clients" render={() => <Redirect to="/login" />} />
      <Route exact path="/rejected-clients" render={() => <Redirect to="/login" />} />
      <Route exact path="/users" render={() => <Redirect to="/login" />} />
      <Route exact path="/pending-users" render={() => <Redirect to="/login" />} />
      <Route exact path="/order-status" render={() => <Redirect to="/login" />} />
      <Route exact path="/portfolio" render={() => <Redirect to="/login" />} />
      <Route exact path="/all-learning-materials" render={() => <Redirect to="/login" />} />
      <Route exact path="/learning-materials-dashboard" render={() => <Redirect to="/login" />} />
      {/* <Route exact path="/learning-materials-by-ringgit-lab" component={LearningMaterialsByRinggitLabDashboard} />
      <Route exact path="/learning-materials-by-ringgit-lab/what-is-stock-trading" component={LearningMaterialBeginnerTopic1} /> */}
      <Route exact path="/learning-materials" render={() => <Redirect to="/login" />} />
      <Route exact path="/learning-materials/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/forum" render={() => <Redirect to="/login" />} />
      <Route exact path="/forum/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/newsletter" render={() => <Redirect to="/login" />} />
      <Route exact path="/request" render={() => <Redirect to="/login" />} />
      <Route exact path="/request-forms" render={() => <Redirect to="/login" />} />
      <Route exact path="/subscriptions" render={() => <Redirect to="/login" />} />
      <Route exact path="/subscription/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/checkout/:id" render={() => <Redirect to="/login" />} />
      {/* <Route exact path="/checkout/:id" component={InvoiceRequestDashboard} /> */}
      <Route exact path="/stripe/error" render={() => <Redirect to="/login" />} />
      <Route exact path="/stripe/successful/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/payment/successful/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/payment/error" render={() => <Redirect to="/login" />} />
      <Route exact path="/checkout/pending/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/brokers" render={() => <Redirect to="/login" />} />
      <Route exact path="/remisiers" render={() => <Redirect to="/login" />} />
      <Route exact path="/remisier/subscription/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/remisier/checkout/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/market-news-admin" render={() => <Redirect to="/login" />} />
      <Route exact path="/market-news-admin/fetch" render={() => <Redirect to="/login" />} />
      <Route exact path="/questionnaire-response" render={() => <Redirect to="/login" />} />
      {/* <Route exact path="/response" component={AuthCreateQuestionnaireResponse} /> */}
      <Route exact path="/thank-you-for-your-response" render={() => <Redirect to="/login" />} />
      <Route exact path="/sorry-to-see-you-go" render={() => <Redirect to="/login" />} />
      <Route exact path="/send-feedback" render={() => <Redirect to="/login" />} />
      <Route exact path="/feedback-response" render={() => <Redirect to="/login" />} />
      <Route exact path="/thank-you-for-your-feedback" render={() => <Redirect to="/login" />} />
      <Route exact path="/profiles/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/create-profile" render={() => <Redirect to="/login" />} />
      <Route exact path="/unpublished-profile" render={() => <Redirect to="/login" />} />
      <Route exact path="/referral" render={() => <Redirect to="/login" />} />
      <Route exact path="/referral-management" render={() => <Redirect to="/login" />} />
      <Route exact path="/referral-management/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/technical-view" render={() => <Redirect to="/login" />} />
      <Route exact path="/technical-view/:id" render={() => <Redirect to="/login" />} />
      <Route exact path="/frequently-asked-questions" render={() => <Redirect to="/login" />} />
      <Route exact path="/stock-screener-elite" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/stocks" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/premium" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/subscription" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/elite" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/top-active" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/top-gainers" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/top-losers" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/52-week-high" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/52-week-low" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/rsi-oversold" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/macd-oversold" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/stochastic-oversold" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/bollinger-band-breakout" render={() => <Redirect to="/login" />} />
      <Route exact path="/screener/bullish-jhook" render={() => <Redirect to="/login" />} />
      <Route exact path="/premium-subscription" render={() => <Redirect to="/login" />} />
      <Route exact path="/stock-mappings" render={() => <Redirect to="/login" />} />
    </>
  );
};

export default NoAuthRoutes;