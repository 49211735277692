import React, { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

const UserDashboard = lazy(() => import('../components/dashboard/UserDashboard'));
const AllLatestUpdates = lazy(() => import('../components/update/AllLatestUpdates'));
const AccountSettings = lazy(() => import('../components/settings/AccountSettings'));
const TradingJournalDashboard = lazy(() => import('../components/trading-journals/TradingJournalDashboard'));
const ImportConfirmationPage = lazy(() => import('../components/trading-journals/ImportConfirmationPage'));
const PotentialStocksAdminDashboard = lazy(() => import('../components/potential-stocks/PotentialStocksAdminDashboard'));
const WatchListDashboard = lazy(() => import('../components/watch-list/WatchListDashboard'));
const PotentialStocksEliteDashboard = lazy(() => import('../components/potential-stocks/PotentialStocksEliteDashboard'));
const PaperTradeDashboard = lazy(() => import('../components/paper-trade/PaperTradeDashboard'));
const UserListDashboard = lazy(() => import('../components/users/UserListDashboard'));
const ClientListDashboard = lazy(() => import('../components/users/ClientListDashboard'));
const PortfolioDashboard = lazy(() => import('../components/portfolio/PortfolioDashboard'));
const PrivateMutualFundsTermsPage = lazy(() => import('../components/terms/PrivateMutualFundsTermsPage'));
const LearningMaterialDashboard = lazy(() => import('../components/learning-material/LearningMaterialDashboard'));
const LearningMaterialContentsDashboard = lazy(() => import('../components/learning-material/LearningMaterialContentsDashboard'));
const ForumDashboard = lazy(() => import('../components/forum/ForumDashboard'));
const ForumThreadPage= lazy(() => import('../components/forum/ForumThreadPage'));
const CardPaymentDashboard = lazy(() => import('../components/stripe/CardPaymentDashboard'));
const NewsletterDashboard = lazy(() => import('../components/newsletter/NewsletterDashboard'));
const RequestUpdateDashboard = lazy(() => import('../components/request/RequestUpdateDashboard'));
const StripeErrorPage = lazy(() => import('../components/stripe/StripeErrorPage'));
const StripeSuccessfulPage = lazy(() => import('../components/stripe/StripeSuccessfulPage'));
// import InvoiceRequestDashboard from '../components/stripe/InvoiceRequestDashboard';
const TransferPendingPage = lazy(() => import('../components/stripe/TransferPendingPage'));
const SubscriptionDashboard = lazy(() => import('../components/subscription/SubscriptionDashboard'));
const RemisierDashboard = lazy(() => import('../components/remisier/RemisierDashboard'));
const RemisierCardPaymentDashboard = lazy(() => import('../components/stripe/RemisierCardPaymentDashboard'));
const RemisierInvoiceRequestDashboard = lazy(() => import('../components/stripe/RemisierInvoiceRequestDashboard'));
const FetchStockNewsAdminDashboard = lazy(() => import('../components/news/FetchStockNewsAdminDashboard'));
const QuestionnaireThankYouPage = lazy(() => import('../components/questionnaire/QuestionnaireThankYouPage'));
const QuestionnaireSorryPage = lazy(() => import('../components/questionnaire/QuestionnaireSorryPage'));
const QuestionnaireDashboard = lazy(() => import('../components/questionnaire/QuestionnaireDashboard'));
const JournalDashboard = lazy(() => import('../components/journal/JournalDashboard'));
const JournalByStockDashboard = lazy(() => import('../components/journal/JournalByStockDashboard'));
const FeedbackDashboard = lazy(() => import('../components/feedback/FeedbackDashboard'));
const CreateFeedbackPage = lazy(() => import('../components/feedback/CreateFeedbackPage'));
const FeedbackThankYouPage = lazy(() => import('../components/feedback/FeedbackThankYouPage'));
const RemisierLatestUpdates = lazy(() => import('../components/update/RemisierLatestUpdates'));
const CreateProfilePage = lazy(() => import('../components/profile/CreateProfilePage'));
const UnpublishedProfile = lazy(() => import('../components/profile/UnpublishedProfile'));
const EditProfilePage = lazy(() => import('../components/profile/EditProfilePage'));
const RequestFormsDashboard = lazy(() => import('../components/request/RequestFormsDashboard'));
const BrokerDashboard = lazy(() => import('../components/remisier/BrokerDashboard'));
const StockNewsAdminDashboard = lazy(() => import('../components/news/StockNewsAdminDashboard'));
const PendingUserListDashboard = lazy(() => import('../components/users/PendingUserListDashboard'));
const PotentialStocksDashboard = lazy(() => import('../components/potential-stocks/PotentialStocksDashboard'));
const ReferralDashboard = lazy(() => import('../components/referral/ReferralDashboard'));
const RejectedClientListDashboard = lazy(() => import('../components/users/RejectedClientListDashboard'));
const TechnicalViewDashboard = lazy(() => import('../components/technical-view/TechnicalViewDashboard'));
const TechnicalViewByStockDashboard = lazy(() => import('../components/technical-view/TechnicalViewByStockDashboard'));
const FAQDashboard = lazy(() => import('../components/faq/FAQDashboard'));
const TradingOrderDashboard = lazy(() => import('../components/trading-order/TradingOrderDashboard'));
const ReferralAdminDashboard = lazy(() => import('../components/referral/ReferralAdminDashboard'));
const ReferralDetailsAdminDashboard = lazy(() => import('../components/referral/ReferralDetailsAdminDashboard'));
const LearningMaterialAdminDashboard = lazy(() => import('../components/learning-material/LearningMaterialAdminDashboard'));
const PotentialStocksPremiumDashboard = lazy(() => import('../components/potential-stocks/PotentialStocksPremiumDashboard'));
const ScreenerPremiumDashboard = lazy(() => import('../components/screener/ScreenerPremiumDashboard'));
const ScreenerEliteDashboard = lazy(() => import('../components/screener/ScreenerEliteDashboard'));
const TopActiveStocksScreener = lazy(() => import('../components/screener/TopActiveStocksScreener'));
const TopGainersScreener = lazy(() => import('../components/screener/TopGainersScreener'));
const TopLosersScreener = lazy(() => import('../components/screener/TopLosersScreener'));
const HighStockScreener = lazy(() => import('../components/screener/HighStockScreener'));
const LowStockScreener = lazy(() => import('../components/screener/LowStockScreener'));
const RSIStockScreener = lazy(() => import('../components/screener/RSIStockScreener'));
const MACDStockScreener = lazy(() => import('../components/screener/MACDStockScreener'));
const StochasticStockScreener = lazy(() => import('../components/screener/StochasticStockScreener'));
const BollingerBandBreakoutStockScreener = lazy(() => import('../components/screener/BollingerBandBreakoutStockScreener'));
const JHookStockScreener = lazy(() => import('../components/screener/JHookBreakoutStockScreener'));
const RinggitLabStripeSuccessfulPage = lazy(() => import('../components/stripe/RinggitLabStripeSuccessfulPage'));
const RinggitLabStripeErrorPage = lazy(() => import('../components/stripe/RinggitLabStripeErrorPage'));
const LearningMaterialMainDashboard = lazy(() => import('../components/learning-material/LearningMaterialMainDashboard'));
const SubscriptionRedirect = lazy(() => import('../components/dashboard/SubscriptionRedirect'));
const PremiumSubscriptionDashboard = lazy(() => import('../components/subscription/PremiumSubscriptionDashboard'));
const ScreenerSubscriptionDashboard = lazy(() => import('../components/screener/ScreenerSubscriptionDashboard'));
const PotentialStocksNonActivePremiumDashboard = lazy(() => import('../components/potential-stocks/PotentialStocksNonActiveUserDashboard'));
const LearningMaterialsByRinggitLabDashboardEn = lazy(() => import('../components/learning-material/LearningMaterialsByRinggitLabDashboardEn'));
const LearningMaterialByRinggitLabContentsEn = lazy(() => import('../components/learning-material/LearningMaterialByRinggitLabContentsEn'));
const BuyCallDashboard = lazy(() => import('../components/potential-stocks/BuyCallDashboard'));
const NewRemisierDashboard = lazy(() => import('../components/remisier/NewRemisierDashboard'));
const TrialUserListDashboard = lazy(() => import('../components/users/TrialUserListDashboard'));
const BuyCallAdminDashboard = lazy(() => import('../components/potential-stocks/BuyCallAdminDashboard'));
const StockMappingDashboard = lazy(() => import('../components/potential-stocks/StockMappingDashboard'));
const JournalAllStocksDashboard = lazy(() => import('../components/journal/JournalAllStocksDashboard'));
// import { CustomizedScreenerDashboard } from '../components/screener/CustomizedScreenerDashboard';
// import { CreateCustomizedScreenerDashboard } from '../components/screener/CreateCustomizedScreenerDashboard';

const AuthRoutes = () => {
  return (
    <>
      <Route exact path="/terms/private-mutual-funds" component={PrivateMutualFundsTermsPage} />
      <Route exact path="/dashboard" component={UserDashboard} />
      <Route exact path="/all-updates" component={AllLatestUpdates} />
      <Route exact path="/all-updates/:id" component={RemisierLatestUpdates} />
      <Route exact path="/settings" component={AccountSettings} />
      <Route exact path="/journal" component={TradingJournalDashboard} />
      <Route exact path="/journals" component={JournalDashboard} />
      <Route exact path="/all-journals" component={JournalAllStocksDashboard} />
      <Route exact path="/journals/:id" component={JournalByStockDashboard} />
      <Route exact path="/journal-confirmation" component={ImportConfirmationPage} />
      <Route exact path="/admin-screener" component={PotentialStocksAdminDashboard} />
      <Route exact path="/potential-stocks" component={PotentialStocksEliteDashboard} />
      <Route exact path="/potential-stocks/premium" component={PotentialStocksPremiumDashboard} />
      <Route exact path="/watchlist" component={WatchListDashboard} />
      <Route exact path="/paper-trade" component={PaperTradeDashboard} />
      <Route exact path="/clients" component={ClientListDashboard} />
      <Route exact path="/rejected-clients" component={RejectedClientListDashboard} />
      <Route exact path="/users" component={UserListDashboard} />
      <Route exact path="/trial-users" component={TrialUserListDashboard} />
      <Route exact path="/pending-users" component={PendingUserListDashboard} />
      <Route exact path="/order-status" component={TradingOrderDashboard} />
      <Route exact path="/portfolio" component={PortfolioDashboard} />
      <Route exact path="/all-learning-materials" component={LearningMaterialAdminDashboard} />
      <Route exact path="/learning-materials-dashboard" component={LearningMaterialMainDashboard} />
      <Route exact path="/en/learning-materials-by-ringgit-lab" component={LearningMaterialsByRinggitLabDashboardEn} />
      <Route exact path="/en/learning-materials-by-ringgit-lab/:id" component={LearningMaterialByRinggitLabContentsEn} />
      <Route exact path="/learning-materials" component={LearningMaterialDashboard} />
      <Route exact path="/learning-materials/:id" component={LearningMaterialContentsDashboard} />
      <Route exact path="/forum" component={ForumDashboard} />
      <Route exact path="/forum/:id" component={ForumThreadPage} />
      <Route exact path="/newsletter" component={NewsletterDashboard} />
      <Route exact path="/request" component={RequestUpdateDashboard} />
      <Route exact path="/request-forms" component={RequestFormsDashboard} />
      <Route exact path="/subscriptions" component={SubscriptionDashboard} />
      <Route exact path="/subscription/:id" component={CardPaymentDashboard} />
      {/* <Route exact path="/checkout/:id" component={InvoiceRequestDashboard} /> */}
      <Route exact path="/stripe/error" component={StripeErrorPage} />
      <Route exact path="/stripe/successful/:id" component={StripeSuccessfulPage} />
      <Route exact path="/payment/successful/:id" component={RinggitLabStripeSuccessfulPage} />
      <Route exact path="/payment/error" component={RinggitLabStripeErrorPage} />
      <Route exact path="/checkout/pending/:id" component={TransferPendingPage} />
      <Route exact path="/brokers" component={BrokerDashboard} />
      <Route exact path="/remisiers/old" component={RemisierDashboard} />
      <Route exact path="/remisiers" component={NewRemisierDashboard} />
      <Route exact path="/remisier/subscription/:id" component={RemisierCardPaymentDashboard} />
      <Route exact path="/remisier/checkout/:id" component={RemisierInvoiceRequestDashboard} />
      <Route exact path="/market-news-admin" component={StockNewsAdminDashboard} />
      <Route exact path="/market-news-admin/fetch" component={FetchStockNewsAdminDashboard} />
      <Route exact path="/questionnaire-response" component={QuestionnaireDashboard} />
      {/* <Route exact path="/response" component={AuthCreateQuestionnaireResponse} /> */}
      <Route exact path="/thank-you-for-your-response" component={QuestionnaireThankYouPage} />
      <Route exact path="/sorry-to-see-you-go" component={QuestionnaireSorryPage} />
      <Route exact path="/send-feedback" component={CreateFeedbackPage} />
      <Route exact path="/feedback-response" component={FeedbackDashboard} />
      <Route exact path="/thank-you-for-your-feedback" component={FeedbackThankYouPage} />
      <Route exact path="/profiles/:id" component={EditProfilePage} />
      <Route exact path="/create-profile" component={CreateProfilePage} />
      <Route exact path="/unpublished-profile" component={UnpublishedProfile} />
      <Route exact path="/referral" component={ReferralDashboard} />
      <Route exact path="/referral-management" component={ReferralAdminDashboard} />
      <Route exact path="/referral-management/:id" component={ReferralDetailsAdminDashboard} />
      <Route exact path="/technical-view" component={TechnicalViewDashboard} />
      <Route exact path="/technical-view/:id" component={TechnicalViewByStockDashboard} />
      <Route exact path="/frequently-asked-questions" component={FAQDashboard} />
      <Route exact path="/stock-recommendations-by-all-remisiers" component={BuyCallAdminDashboard} />
      <Route exact path="/stock-recommendations-by-remisier/:id" component={BuyCallDashboard} />
      <Route exact path="/stock-mappings" component={StockMappingDashboard} />
      <Route exact path="/screener" component={PotentialStocksDashboard} />
      {/* <Route exact path="/customized-screener/:id" component={CustomizedScreenerDashboard} />
      <Route exact path="/customized-screener/:id/create" component={CreateCustomizedScreenerDashboard} /> */}
      <Route exact path="/screener/stocks" component={PotentialStocksNonActivePremiumDashboard} />
      <Route exact path="/screener/premium" component={ScreenerPremiumDashboard} />
      <Route exact path="/screener/elite" component={ScreenerEliteDashboard} />
      <Route exact path="/screener/subscription" component={ScreenerSubscriptionDashboard} />
      <Route exact path="/screener/top-active" component={TopActiveStocksScreener} />
      <Route exact path="/screener/top-gainers" component={TopGainersScreener} />
      <Route exact path="/screener/top-losers" component={TopLosersScreener} />
      <Route exact path="/screener/52-week-high" component={HighStockScreener} />
      <Route exact path="/screener/52-week-low" component={LowStockScreener} />
      <Route exact path="/screener/rsi-oversold" component={RSIStockScreener} />
      <Route exact path="/screener/macd-oversold" component={MACDStockScreener} />
      <Route exact path="/screener/stochastic-oversold" component={StochasticStockScreener} />
      <Route exact path="/screener/bollinger-band-breakout" component={BollingerBandBreakoutStockScreener} />
      <Route exact path="/screener/bullish-jhook" component={JHookStockScreener} />
      <Route exact path="/premium-subscription" component={SubscriptionRedirect} />
      <Route exact path="/admin-premium-subscription" component={PremiumSubscriptionDashboard} />

      <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/login" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/login/ringgit-lab-elite" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/reset-password" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/verify-account" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/verify-account/ringgit-lab-elite" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/signup" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/signup/remisier/:id" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/signup/subscription" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/signup/remisier" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/signup/private-mutual-funds" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/email-verification" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/remisier" render={() => <Redirect to="/dashboard" />} />
      {/* <Route exact path="/questionnaire" render={() => <Redirect to="/response" />} /> */}
    </>
  );
};

export default AuthRoutes;
