import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Hub } from 'aws-amplify';
import './App.scss';
import AuthClient from './utils/AuthClient';
import GraphqlClient from './utils/GraphqlClient';
import { User } from './models/User';
import { modelFactory } from './models/ModelFactory';
import { UserContext } from './UserContext';
import AuthRoutes from './routes/AuthRoutes';
import NoAuthRoutes from './routes/NoAuthRoutes';
import { CommonRoutes } from './routes/CommonRoutes';
// import { UserAccessModel } from './models/UserAccess';
import LoadingSpinner from './components/shared/LoadingSpinner';

function App() {
  const [user, setUser] = useState<User>(null);
  const [userAuth, setUserAuth] = useState(false);
  const [userData, setUserDataState] = useState<User>(null);
  const setUserData = useCallback((userData: User) => {
    const userdata = userData ?? {};
    const user = modelFactory<User>(userdata, User);
    setUserDataState(user);
  }, []);
  const value = useMemo(() => ({ userData, setUserData }), [userData, setUserData]);

  useEffect(() => {
    getUserData();
    Hub.listen("auth", (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = async () => {
    try {
      const user = await AuthClient.currentAuthenticatedUser();
      const result = await GraphqlClient.getUser(user.attributes.sub);
      const userData = result.data.getUser;
      if (userData) {
        setUserData(userData);
        // const userAccessId = userData?.id;
        // const filter = {
        //   userId: { eq: userAccessId }
        // };
        // const results = await Promise.all([
        //   GraphqlClient.listUserAccesss(filter)
        // ]);
        // const userAccess = results[0].map(i =>
        //   modelFactory<UserAccessModel>(i, UserAccessModel)
        // );

        // if (userAccess.length !== 0) {
        //   const userAccessForm = Object.assign([], userAccess[0].accessLogs);
        //   userAccessForm.push({
        //     dateTime: new Date (),
        //     url: "/",
        //     event: "auth"
        //   })
        //   const input = {
        //     id: userAccess[0].id,
        //     userId: userData?.id,
        //     userType: userData.userType,
        //     accessLogs: userAccessForm
        //   }
        //   await GraphqlClient.updateUserAccess(input);
        // }
        // if (userAccess.length === 0) {
        //   const input = {
        //     userId: userData?.id,
        //     userType: userData.userType,
        //     accessLogs: {dateTime: new Date(), url: "/", event: "auth"}
        //   }
        //   await GraphqlClient.createUserAccess(input);
        // }
      } else {
        const input = {
          id: user.attributes.sub,
          username: user.username,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.attributes.email,
          registered: true,
          userType: "admin"
        };
        const response = await GraphqlClient.registerUser(input);
        setUserData(response.data.registerUser);
      }
      setUser(user);
      setUserAuth(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err, "not logged in");
      setUserAuth(true);
    }
  };

  const onAuthEvent = payload => {
    switch (payload.event) {
    case "signIn":
      getUserData();
      break;
    case "signUp":
      break;
    case "signOut":
      setUser(null);
      setUserData(null);
      break;
    default:
      return;
    }
  };

  if (userAuth === false) {
    return <LoadingSpinner />
  }

  return (
    <>
      <UserContext.Provider value={value}>
        <div className="App">
          {/* <CookieConsent
            disableStyles={true}
            location="bottom"
            buttonText="GOT IT"
            cookieName="_ringgit_lab"
            buttonClasses="consent-text btn-primary"
            containerClasses="consent-body"
            contentClasses="text-capitalize"
            expires={150}
          >
            This website uses cookies to ensure you get the best experience on our website.{" "}
            <Link to="/privacy-policy">Cookies Policy</Link>
          </CookieConsent> */}
          {
            user ? (
              <div className="user">
                <AuthRoutes />
              </div>
            ) : (
              <>
                <NoAuthRoutes />
              </>
            )
          }
          <CommonRoutes />
        </div>
      </UserContext.Provider>
    </>
  );
}

export default App;