import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary';
import { BrowserRouter, Switch } from 'react-router-dom';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import LoadingSpinner from './components/shared/LoadingSpinner';

Amplify.configure(aws_exports);

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <App />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
