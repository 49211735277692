export class User {
  private json: any;
  id: string;
  userAccessId: string;
  userAccessHistoryId: string;
  accountType: "cds" | "subscription" | "mutual";
  changeStatus: string;
  changedBy: string;
  accountStatus: "active" | "non_active" | "trial" | "pending" | "unverified" | "registered" | "expired" | "rejected";
  registeredDate: string;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumber: string;
  address: string;
  identityCard: string;
  clientCode: string;
  cdsNumber: string;
  broker: string;
  totalCapital: number;
  paperTradeCapital: number;
  remisierCode: string;
  commonCode: string;
  verified: boolean;
  subscriptionId: string;
  subscriptionType: "recurring" | "one_time" | "trial" | "basic" | "standard" | "pro" | "enterprise";
  subscriptionPriceId: string;
  subscriptionExpiryDate: string;
  subscriptionStatus: string;
  platformSubscriptionId: string;
  remisierSubscriptionId: string;
  newRemisierSubscriptionId: string;
  remisierLearningMaterialNumber: number;
  stripeAccountId: string;
  stripePortalUrl: string;
  productId: string;
  cdsClientId: string;
  dailyEmail: string;
  btstEmail: string;
  newsletterEmail: string;
  userType: "super_admin" | "admin" | "client" | "user" | "mutual" | "remisier" | "sub_admin" | "subscriber";
  profileId: string;
  userFeedback: string;
  clientSurvey: string;
  clientReferralId: string;
  batch: string;
  notes: string;
  activation: string;
  fullAccess: string;
  referralCode: string;
  referredByCode: string;
  premiumSubscriber: boolean;
  premiumSubscriptionId: string;
  premiumSubscriptionStatus: string;
  premiumSubscriptionStripeId: string;
  premiumSubscriptionStripeAccountId: string;
  premiumSubscriptionPriceId: string;
  premiumSubscriptionType: string;
  premiumSubscriptionMonth: string;
  premiumSubscriptionExpiryDate: string;
  affiliateCode: string;
  affiliateByCode: string;
  affiliateId: string;

  constructor(json) {
    this.json = json;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  isAdmin() {
    return this.userType === "admin";
  }

}
