import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

// import StockNewsDashboard from '../components/news/StockNewsDashboard';
const AccountOpeningGuidancePage = lazy(() => import('../components/landing/AccountOpeningGuidancePage'));
const StockNewsDetailsPage = lazy(() => import('../components/news/StockNewsDetailsPage'));
const CalculatorDashboard = lazy(() => import('../components/calculator/CalculatorDashboard'));
const StockDashboard = lazy(() => import('../components/stock/StockDashboard'));
const StockDetailsPage = lazy(() => import('../components/stock/StockDetailsPage'));
const PaperTradingPage = lazy(() => import('../components/landing/PaperTradingPage'));
const TradingJournalPage = lazy(() => import('../components/landing/TradingJournalPage'));
const StartTradingPage = lazy(() => import('../components/landing/StartTradingPage'));
const StockScreenerPage = lazy(() => import('../components/landing/StockScreenerPage'));
const MPlusOnlinePage = lazy(() => import('../components/landing/MPlusOnlinePage'));
const PrivacyPolicyPage = lazy(() => import('../components/terms/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('../components/terms/TermsOfServicePage'));
const TermsForRemisierPage = lazy(() => import('../components/terms/TermsForRemisierPage'));
const ReferralTermsPage = lazy(() => import('../components/terms/ReferralTermsPage'));
const PremiumPage = lazy(() => import('../components/landing/PremiumPage'));
const ProfilePage = lazy(() => import('../components/profile/ProfilePage'));
// import AffiliatePage from '../components/landing/AffiliatePage';
// import AffiliateTermsPage from '../components/terms/AffiliateTermsPage';

export const CommonRoutes = () => {
  return (
    <>
      <Route exact path="/mplus-online-cds-registration" component={AccountOpeningGuidancePage} />
      {/* <Route exact path="/market-news" component={StockNewsDashboard} /> */}
      <Route exact path="/market-news/:id" component={StockNewsDetailsPage} />
      <Route exact path="/stocks" component={StockDashboard} />
      <Route exact path="/stocks/:id" component={StockDetailsPage} />
      <Route exact path="/brokerage-calculator" component={CalculatorDashboard} />
      <Route exact path="/paper-trading" component={PaperTradingPage} />
      <Route exact path="/trading-journal" component={TradingJournalPage} />
      <Route exact path="/stock-screener" component={StockScreenerPage} />
      <Route exact path="/start-trading-stocks" component={StartTradingPage} />
      <Route exact path="/mplus-online" component={MPlusOnlinePage} />
      <Route exact path="/premium" component={PremiumPage} />
      {/* <Route exact path="/affiliates" component={AffiliatePage} /> */}
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/terms" component={TermsOfServicePage} />
      <Route exact path="/terms/ringgit-lab-for-remisier" component={TermsForRemisierPage} />
      <Route exact path="/terms/ringgit-lab-elite-referral-program" component={ReferralTermsPage} />
      {/* <Route exact path="/terms/ringgit-lab-premium-affiliate" component={AffiliateTermsPage} /> */}
      <Route exact path="/remisier-profiles/:id" component={ProfilePage} />
    </>
  );
};

export const CommonRoutesInUser = () => {
  return (
    <>
    </>
  );
};
